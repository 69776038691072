// Stylesheets
import './main.scss';

// Imports all component based scss files.
import './components/**/*.scss';
// import favicon here.
import './resources/images/favicon.png';

declare global {
  interface Window {
    Bus: any; // event bus
    OneTrust: any;
    _tnsInstances: any; //carousel
    _tabbyInstances: any; //tabs
    _plyrInstances: any; //vimeo embed
    _badgerInstances: any; //accordion
  }
}

const CONSTANTS = {
  header: {
    className: 'header',
    menuTrigger: 'header__menu-trigger',
    menuCloseTrigger: 'header__menu-close-trigger',
    showMobileMenu: 'show-mobile-menu',
    stickyClassName: 'sticky',
  },
};

(() => {
  // This function will help to close the opened menu on user interaction
  const menuCloseHandler = () => {
    const menuTrigger = document.querySelector(
      `#${CONSTANTS.header.menuTrigger}`
    ) as HTMLElement;
    const header = document.querySelector(
      '.' + CONSTANTS.header.className
    ) as HTMLElement;

    if (menuTrigger && header && window.Bus) {
      window.Bus.on('emu-button:click', ({ id }) => {
        // close button clicked
        if (id === CONSTANTS.header.menuCloseTrigger) {
          menuTrigger?.click();
          header?.classList.remove(CONSTANTS.header.showMobileMenu);
        }

        // menu open clicked
        if (id === CONSTANTS.header.menuTrigger) {
          header?.classList.add(CONSTANTS.header.showMobileMenu);
        }
      });
    }
  };

  // to make header nav container sticky on scroll
  const handleNavStickyState = () => {
    const header = document.querySelector('header.header') as HTMLElement;

    if (header) {
      window.addEventListener('scroll', function () {
        if (window.scrollY >= 40) {
          header.classList.add(CONSTANTS.header.stickyClassName);
        } else {
          header.classList.remove(CONSTANTS.header.stickyClassName);
        }
      });
    }
  };

  // when resized, making sure that the accordion heights are recalculated
  const handleAccordions = () => {
    window.addEventListener('resize', () => {
      const instances = window._badgerInstances;
      if (instances && Object.keys(instances).length) {
        Object.keys(instances).forEach(instKey => {
          const inst = instances[instKey];
          inst?.calculateAllPanelsHeight?.();
        });
      }
    });

    window.Bus.on('emu-accordion:toggle', ({ id, itemClicked }) => {
      const badgerInstance = window._badgerInstances[id];
      const accEl = document.querySelector(`#${id}`);
      if (accEl && badgerInstance && itemClicked?.id) {
        const itemClickedButton = accEl
          .querySelector(`#${itemClicked.id} .js-badger-accordion-header`)
          ?.getAttribute('aria-controls');
        const accordionContent = accEl.querySelector(`#${itemClickedButton}`);

        if (accordionContent) {
          badgerInstance.calculatePanelHeight(accordionContent);
        }
      }
    });
  };

  // animates sections based on threshold whenever the section comes into view
  const animateSection = () => {
    const sections = document.querySelectorAll(
      '.animated-section'
    ) as NodeListOf<HTMLElement>;

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animated-section--animated');

            if (!entry.target.classList.contains('animated-section--always')) {
              observer.disconnect();
            }
          } else {
            entry.target.classList.remove('animated-section--animated');
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    sections.forEach(section => {
      observer.observe(section);
    });
  };

  // Custom tabs component, as the default tabs component from AAAEM Common does not support more than one line in the tab trigger
  const initiateCustomTabs = tabSection => {
    const tabs = tabSection.querySelectorAll(
      '.dg-tabs__nav-links'
    ) as NodeListOf<HTMLElement>;

    const tabContents = tabSection.querySelectorAll(
      '.dg-tabs__ba-section-wrapper'
    ) as NodeListOf<HTMLElement>;

    // This function will handle the active classes that needs to be added in order to achieve the tab functionality in proven results page.
    const showTab = index => {
      const targetContent = tabContents[index];

      tabs.forEach(content => {
        content.classList.remove('nav--active');
      });

      tabContents.forEach(content => {
        content.classList.remove('tab--active');
      });

      if (targetContent) {
        targetContent.classList.add('tab--active');
        tabs[index].classList.add('nav--active');
      }
    };

    // initially show the first tab.
    if (tabs.length && tabContents.length) {
      tabs.forEach((tab, index) => {
        tab.addEventListener('click', function (e) {
          e.preventDefault();
          const tabElement = e.target;

          if (tabElement instanceof HTMLAnchorElement) {
            showTab(index);
          }
        });
      });

      showTab(0);
    }
  };

  // This function will handle the before after tab functionality in proven results page.
  const handleBeforeAfterTab = () => {
    const customTabs = document.querySelectorAll('.dg-tabs__content');

    customTabs.forEach(tabSection => {
      initiateCustomTabs(tabSection);
    });
  };

  const init = () => {
    if (window.Bus) {
      // listen to events here
    }

    animateSection();
    handleAccordions();
    menuCloseHandler();
    handleNavStickyState();
    handleBeforeAfterTab();
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
